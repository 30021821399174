
































































































































import { defineComponent } from "@vue/composition-api";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { mapGetters } from "vuex";

interface ISubItem {
  key?: string;
  id?: string;
  documentKindId: string;
  storageTermKey: string;
  storageTermValue: string;
  comment?: string;
}

export default defineComponent({
  name: "DocumentTreeDocItem",
  components: {
    SelectFilter,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isSection: {
      type: Boolean,
    },
  },
  data() {
    return {
      numberItems: [
        {
          title: "Документы",
          value: "docs",
        },
        {
          title: "Балансы, Отчёт",
          value: "balance",
        },
      ],
      tmpItems: [] as ISubItem[],
    };
  },
  computed: {
    ...mapGetters("docTypeView", ["storageTerms"]),
    resultSubItems(): ISubItem[] {
      if (this.isSection) {
        return [];
      }
      return (this.item.storageTermList || []).concat(this.tmpItems);
    },
    storageItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.storageTerms, "value", "id");
    },
  },
  methods: {
    deleteElementCb() {
      if (!this.item.id) {
        this.$emit("delete");
        return;
      }
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () => this.$emit("delete"),
          title: `Удалить ${this.isSection ? "секцию" : "документ"}?`,
        })
      );
    },
    addNewSubDoc() {
      this.tmpItems.push({
        key: `${Math.random()}-key`,
        documentKindId: this.item.id,
        storageTermKey: "",
        storageTermValue: "",
        comment: "",
      });
    },
    updateSubitem(subItem: ISubItem, fieldKey: string, value: string) {
      if (subItem.id) {
        const resultSubItems = this.item.storageTermList.map((item: ISubItem) => {
          if (item.id !== subItem.id) {
            return item;
          }
          return {
            ...item,
            [fieldKey]: value,
          };
        });
        this.$emit("update", {
          ...this.item,
          storageTermList: resultSubItems,
        });
      } else {
        const { key, ...result } = subItem;
        if (fieldKey === "storageTermValue" && value) {
          const resultSubItems = this.item.storageTermList.concat({
            ...result,
            [fieldKey]: value,
          });
          this.$emit("update", {
            ...this.item,
            storageTermList: resultSubItems,
          });
        } else {
          this.tmpItems = this.tmpItems.map((tmpItem) => {
            if (tmpItem.key !== key) {
              return tmpItem;
            }
            return {
              ...tmpItem,
              [fieldKey]: value,
            };
          });
        }
      }
    },
    deleteSubDoc(subItem: ISubItem) {
      if (!subItem.id) {
        this.tmpItems = this.tmpItems.filter((item) => item.key !== subItem.key);
      } else {
        this.$emit("update", {
          ...this.item,
          storageTermList: this.item.storageTermList.filter((item: ISubItem) => item.id !== subItem.id),
        });
      }
    },
    onChange(key: string, value: string) {
      this.$emit("update", {
        ...this.item,
        [key]: value,
      });
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.tmpItems = [];
      },
    },
  },
});
